import React from "react"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import "./contact.scss"

const NotFoundPage = () => (
  <Layout meta={{title: "Not Found"}}>
    <PageHero 
      title="404"
      className="whole-page center"
      subtitle="Page not found!"
      staticImage="/img/page-404-hero.png"
    />
  </Layout>
)

export default NotFoundPage
